<template>
	<tr>
		<td>
			<input class="checkbox-height" type="checkbox" :checked="item.checked"
				   v-on:change="checkboxBlog(item)"/>
		</td>
		<td>{{ item.title }}</td>
		<td><span v-html="renderArrays(item.category)"></span></td>
		<td><span v-html="renderArrays(item.tags)"></span></td>
		<td>
                              <span v-if="item.visibility === 'Published'"
									class="bg-green-500 px-2 py-1 rounded-full text-white">{{
									  $t('published')
								  }}</span>
			<span v-else class="bg-blue-800 px-2 py-1 rounded-full text-white">{{
					$t('draft')
				}}</span>
		</td>
		<td>	<span class="position-relative">
				<span class="w-full badge h-8 text-white cursor-pointer " @click="fireOpenRelevant"
					  :class="{'bg-green-custom': relevantBlog === false,'bg-blue-500': relevantBlog === true}">
				<span class="relevant" v-if="relevantBlog === false">{{ $t('all') }}</span>
				<span class="relevant" v-else>{{ $t('CustomerSpecific') }}</span>

			</span>
				<div class="absolute w-60 bg-white rounded border shadow-lg md:-ml-20 top-absolute"
					 v-if="relevantOpen=== true">
							 <div>
                    <div class="bg-white border-b" v-on:click="onChangeRelevant(false)">
                        <label class="inline-flex align-items-center item-center mt-1 ml-3">
                            <span class="ml-2 cursor-pointer text-xs">{{ $t('all') }} </span>
                        </label>
                    </div></div>
                    <div class="bg-white border-b" v-on:click="onChangeRelevant(true)">
                        <label class="inline-flex align-items-center item-center mt-1 ml-3">
                            <span class="ml-2 cursor-pointer text-xs">{{ $t('CustomerSpecific') }} </span>
                        </label>
                    </div>
				</div>

			</span></td>
		<td>{{ item.date_published }}</td>
		<td>{{ item.date_created }}</td>
		<td class="flex">
			<router-link :to="{name: 'editblog', params: {id: item.id}}"
						 class="flex justify-center bg-blue-900 align-items-center h-7 w-7 rounded-full cursor-pointer">
				<img src="./../../../assets/images/icons/edit.png" alt="">
			</router-link>
			<span v-if="item.is_pin_as_top_post === true" v-on:click="unPinBlog"
				  class="flex justify-center  align-items-center h-7 w-7 rounded-full help-tooltip cursor-pointer ml-1">
							<img src="./../../../assets/images/icons/Paomedia-Small-N-Flat-Pin.512.png" alt="pin-icon"
								 class="w-4 h-4"/>
						</span>
		</td>
	</tr>
</template>

<script>
export default {
	name: 'BlogItem',
	props: {
		item: {
			type: Object,
			required: true
		}
	},
	data: function () {
		return {
			relevantOpen: false,
			relevantText: '',
			relevantBlog: false,
		}
	},
	methods: {
		fireOpenRelevant: function () {
			this.relevantOpen = !this.relevantOpen
		},
		checkboxBlog(e) {
			this.$store.commit('portal/TOGGLE_BLOG_CHECKBOX', {id: this.item.id, value: this.item.checked})
		},
		renderArrays(data) {
			const result = []
			let html = ''
			if (data.length > 0) {
				data.map(v => result.push(v.name))

				result.forEach(element => {
					html += `<span class='px-2 text-gray-700 py-1 border rounded-full badge badge-white'>${element}</span>&nbsp;`
				})
				return html
			}
		},
		onChangeRelevant(status) {
			this.relevantBlog = status
			this.relevantOpen = false
			if (status === false) {
				this.$store.dispatch('portal/updateRelevantBlogCustomer', {
					id: this.item.id,
					data: {customers: []}
				})
					.then(_ => {
						this.$services.helpers.notification(this.$t('blogDataSuccess'), 'success', this)
						window.Bus.$emit('fetch-blogs')
					})
					.catch(err => {
						if (err.response.status === 401) {
							this.$store.commit('auth/CLEAR_AUTH_USER', null)
							window.Bus.$emit('sign-out')
						}
					})
			} else {
				window.Bus.$emit('customer-specific-blog', {blog: this.item})
			}


		},
		unPinBlog: function () {
			let description = this.$t('unpinBlog')
			let self = this
			try {
				this.$confirm(`${description}`, `${self.$t('info')}`, {
					confirmButtonText: this.$t('yes'),
					cancelButtonText: this.$t('cancel'),
					type: 'info',
					beforeClose: (action, instance, done) => {
						if (action === 'confirm') {
							instance.confirmButtonLoading = true
							instance.confirmButtonText = self.$t('loading')
							let formdata = new FormData()
							formdata.append('id', self.item.id)
							formdata.append('title', self.item.title)
							formdata.append('is_pin_as_top_post', 0)
							self.$store.dispatch('portal/updateBlogPost', formdata).then(_ => {
								instance.confirmButtonLoading = false
								done()
								self.item.is_pin_as_top_post = false
								self.$services.helpers.notification(self.$t('blogUpdated'), 'success', self)
							}).catch(error => {
								done()
								instance.confirmButtonLoading = false
								if (error.response.status === 400 || error.response.status === 500 || error.response.status === 403) {
									self.$services.helpers.notification(self.$t('serverError'), 'error', self)
								}
							})
						} else {
							done()
							instance.confirmButtonLoading = false
						}
					}
				}).then(() => {
				})
					.catch(() => {

					})
			} catch (e) {}
		},
		onProductRecommend: function () {
			window.Bus.$emit('blog-product-recommend', {blog: this.item})
		}
	},
	mounted() {
		if (this.item.is_customer_specific === false) {
			this.relevantText = this.$t('all')
			this.relevantBlog = false
		} else {
			this.relevantText = this.$t('customer_specific')
			this.relevantBlog = true
		}
	}
}
</script>

<style scoped>
.bg-green-custom {
	background: #0CD775;
}

.bg-relevant {
	background: #e5e7eb;
	padding: 10px 5px;
	text-align: center;
	border-radius: 7px;
	font-weight: 600;
	width: 100%;
}

.relevant {
	position: relative;
	top: 7px;
	font-weight: 700;
	font-size: 10px;
}

.top-absolute {
	z-index: 9999;
	text-align: left;
	width: 205%;
	left: 78px;
}

.help-tooltip {
	background: transparent;
	background-size: 14px auto;
}
</style>

<template>
	<div class="app-main__inner">
		<div class="container mb-3 dm-sans-font">
			<div class="row">
				<div class="col-sm-12 col-md-4 col-lg-4 pl-0">
					<div class="flex justify-between align-items-center space-x-3 mb-3 cursor-pointer -3">
            <span data-cy='article-heading' class="text-sm md:text-2xl font-semibold text-gray-900 dashboard-head-size">{{
					$t('allPost')
				}}</span></div>
				</div>
				<div class="col-sm-12 col-md-8 col-lg-8 pl-0 pr-0">
					<input v-on:input="blogSearch($event)" type="text" v-model="search"
						   class="sm:float-left lg:float-right w-full lg:w-4/6 align-items-center space-x-4 focus:outline-none bg-white border px-3 py-2 rounded-md text-gray-500"
						   v-bind:placeholder="$t('search')">
				</div>
			</div>

			<div class="row mt-2">
				<div class="col-lg-12 bg-white rounded-md border">
					<div class="py-3 px-2 flex flex-col">
						<div class="flex flex-col lg:flex-row lg:space-x-4">
							<div class="flex flex-col">
								<span class="font-semibold">{{ $t('action') }}</span>
								<div class="flex space-x-3">
									<el-select v-model="deleteType" clearable :placeholder="$t('search')">
										<el-option
											v-for="(item,key) in [{key:'mass',name: $t('massDelete')}]"
											:key="key"
											:label="item.name"
											:value="item.key">
										</el-option>
									</el-select>
									<el-button :loading="processing"
											   class="flex justify-center align-items-center space-x-4 focus:outline-none border px-2 py-1 rounded-md bg-green-500 text-white w-1/2 text-center"
											   v-on:click="blogMassDelete">
										{{ $t('execute') }}
									</el-button>
								</div>
							</div>
							<div class="flex flex-col">
								<span class="font-semibold">{{ $t('dateRange') }}</span>
								<div class="flex space-x-3 w-60">
									<el-date-picker
										class="w-1/2"
										size="small"
										v-model="dateRangeFilter"
										type="daterange"
										format="yyyy-MM-dd"
										value-format="yyyy-MM-dd"
										range-separator="|">
									</el-date-picker>

								</div>
							</div>
							<div class="flex flex-col">
								<span class="font-semibold">{{ $t('category') }}</span>
								<div class="flex space-x-3">
									<el-select v-model="categoryFilter" filterable clearable
											   :placeholder="$t('search')">
										<el-option
											v-for="(category,key) in GET_BLOG_CATEGORY"
											:key="key"
											:label="category.name"
											:value="category.name">
										</el-option>
									</el-select>
									<button v-on:click="filterBlog"
											class="flex justify-center align-items-center space-x-4 focus:outline-none border px-2 py-1 rounded-md bg-green-500 text-white w-1/2 text-center">
										Filter
									</button>
								</div>
							</div>
						</div>
						<div class="mt-4">
							<div class="table-responsive rounded-lg bg-white border">
								<table v-loading='loading' style="width: 100%;" class="table table-hover">
									<thead>
									<tr>
										<th width='10px'><input type="checkbox" name="checkboxAll" v-model="checkboxAll"
																v-on:click="toggleAllCheckbox"
																style="font-size: 14px !important"
																class="font-xs checkbox-height"/></th>
										<th class="text-xs" width='170px'>{{ $t('blogTitle') }}</th>
										<th class="text-xs" width='100px'>{{ $t('category') }}</th>
										<th class="text-xs" width='100px'>{{ $t('blogTag') }}</th>
										<th class="text-xs" width='100px'>Status</th>
										<th class="text-xs" width='100px'>{{ $t('blogVisibilty') }}</th>
										<th class="text-xs" width='100px'>{{ $t('publishDate') }}</th>
										<th class="text-xs w-24">{{ $t('createdDate') }}</th>
										<th class="text-xs w-24">{{ $t('action') }}</th>
									</tr>
									</thead>
									<tbody>
									<blog-item :item="item" v-for="item in GET_BLOG_POST.data" :key="item.id"/>
									<!--									<tr v-for="item in GET_BLOG_POST.data" :key="item.id">-->
									<!--										<td>-->
									<!--											<input class="checkbox-height" type="checkbox" :checked="item.checked"-->
									<!--												   v-on:change="checkboxBlog(item)"/>-->
									<!--										</td>-->
									<!--										<td>{{ item.title }}</td>-->
									<!--										<td><span v-html="renderArrays(item.category)"></span></td>-->
									<!--										<td><span v-html="renderArrays(item.tags)"></span></td>-->
									<!--										<td>-->
									<!--                              <span v-if="item.visibility === 'Published'"-->
									<!--									class="bg-green-500 px-2 py-1 rounded-full text-white">{{-->
									<!--									  item.visibility-->
									<!--								  }}</span>-->
									<!--											<span v-else class="bg-blue-800 px-2 py-1 rounded-full text-white">{{-->
									<!--													item.visibility-->
									<!--												}}</span>-->
									<!--										</td>-->
									<!--										<td><span></span></td>-->
									<!--										<td>{{ item.date_published }}</td>-->
									<!--										<td>{{ item.date_created }}</td>-->
									<!--										<td>-->
									<!--											<router-link :to="{name: 'editblog', params: {id: item.id}}"-->
									<!--														 class="flex justify-center bg-blue-900 align-items-center h-7 w-7 rounded-full cursor-pointer">-->
									<!--												<img src="./../../assets/images/icons/edit.png" alt="">-->
									<!--											</router-link>-->
									<!--										</td>-->
									<!--									</tr>-->
									</tbody>
								</table>
							</div>

						</div>

					</div>

				</div>
				<div class="col-md-12">
					<div class="flex flex-col md:flex-row justify-between mb-4">
                                <span class="flex justify-between align-items-center space-x-5 mt-4">
                                    <!-- <span class="text-base">{{ $t('show') }}</span>
                                    <span>
                                        <select class="form-select">
                                            <option value="50" selected>50</option>
                                            <option value="100">100</option>
                                            <option value="200">200</option>
                                            <option value="400">400</option>
                                            <option value="500">500</option>
                                        </select>
                                    </span> -->
                                </span>
						<div class="pagination-block" v-if="GET_BLOG_POST.data"><!--GET_ARTICLES.total_pages--->
							<paginate
								:page-count="GET_BLOG_POST.total_pages"
								:click-handler="changePageNumber"
								prev-text="<"
								next-text=">"
								:container-class="'pagination space-x-2'">
							</paginate>
						</div>
					</div>
				</div>
			</div>


		</div>
		<!-- <Notification /> -->
	</div>
</template>

<script>
import Vue from 'vue'
import {DatePicker} from 'element-ui'
import {mapGetters} from 'vuex'
import Paginate from 'vuejs-paginate'
import BlogItem from "./components/BlogItem";

Vue.use(DatePicker)

export default {
	name: 'BlogList',
	components: {Paginate, BlogItem},
	data() {
		return {
			loading: false,
			checkboxAll: false,
			processing: false,
			dateRangeFilter: '',
			categoryFilter: '',
			deleteType: '',
			search: '',
			limit: 40
		}
	},

	computed: {
		...mapGetters({
			GET_BLOG_POST: 'portal/GET_BLOG_POST',
			GET_BLOG_CATEGORY: 'portal/GET_BLOG_CATEGORY',
			GET_CHECKED_BLOG_POST: 'portal/GET_CHECKED_BLOG_POST'
		})
	},
	mounted() {
		this.getBlogs(this.limit)
		this.getBlogCategory()
		this.getAllEndCustomers()
		window.Bus.$on('fetch-blogs', e => {
			this.getBlogs(this.limit)
		})
	},
	methods: {
		checkboxBlog(item) {
			this.$store.commit('portal/TOGGLE_BLOG_CHECKBOX', {id: item.id, value: item.checked})
		},
		changePageNumber(number) {
			this.loading = true
			let pageUrl = `?page=${number}&limit=${this.limit}`
			if (this.dateRangeFilter !== '') {
				const data = this.dateRangeFilter
				pageUrl = pageUrl + `&date_from=${data[0]}&date_to=${data[1]}`
			}
			if (this.categoryFilter !== '') {
				pageUrl = pageUrl + `&category=${this.categoryFilter}`
			}
			this.$store.dispatch('portal/getBlogs', {url: pageUrl})
				.then(_ => {
					this.loading = false
				}).catch((err) => {
				if (err.response.status === 401) {
					this.$store.commit('auth/CLEAR_AUTH_USER', null)
					window.Bus.$emit('sign-out')
				}
			})
		},
		getBlogCategory() {
			this.$store.dispatch('portal/getBlogCategory')
		},
		getBlog() {
			this.loading = true
			this.$store.dispatch('portal/getBlog')
				.then(_ => {
					this.loading = false
				})
				.catch(_ => {
					this.loading = false
				})
		},
		getBlogs(limit = null) {
			this.loading = true
			this.$store.dispatch('portal/getBlogs', {url: `?limit=${limit}`})
				.then(_ => {
					this.loading = false
				}).catch((err) => {
				this.loading = false
				if (err.response.status === 401) {
					this.$store.commit('auth/CLEAR_AUTH_USER', null)
					window.Bus.$emit('sign-out')
				}
			})
		},
		renderArrays(data) {
			const result = []
			let html = ''
			if (data.length > 0) {
				data.map(v => result.push(v.name))
// return result.join(', ')
				result.forEach(element => {
					html += `<span class='px-2 text-gray-700 py-1 border rounded-full'>${element}</span>&nbsp;`
				})
				return html
			}
		},
		renderBackground() {
			const color = ['#512DA8', '#EE4D81', '#344258', '#7E3469', '#546295', '#1E2C41']
			return color[Math.floor(Math.random() * color.length)]
		},
		toggleAllCheckbox() {
			this.checkboxAll = !this.checkboxAll
			this.$store.commit('portal/TOGGLE_ALL_BLOG_CHECKBOX', this.checkboxAll)
		},
		blogSearch(event) {
			if (this.search !== '') {
				let pageUrl = `?limit=${this.limit}&search=${this.search}`
				this.$store.dispatch('portal/getBlogs', {url: pageUrl})
					.then(_ => {
						this.loading = false
					}).catch((err) => {
					if (err.response.status === 401) {
						this.$store.commit('auth/CLEAR_AUTH_USER', null)
						window.Bus.$emit('sign-out')
					}
				})
				this.$store.commit('portal/FILTER_BLOG_SEARCH', {value: event.target.value})
			} else {
				this.getBlogs(this.limit)
			}
		},
		blogMassDelete() {
			if (this.GET_CHECKED_BLOG_POST.length <= 0) {
				return this.$services.helpers.notification(this.$t('noPostSeleted'), 'error', this)
			}
			const payload = []
			this.GET_CHECKED_BLOG_POST.map(item => {
				payload.push({id: item.id})
			})
			this.processing = true
			this.$store.dispatch('portal/deleteBlogPost', {blog_posts: payload}).then(_ => {
				this.processing = false
				this.checkboxAll = false
				this.$services.helpers.notification(this.$t('postDeleted'), 'success', this)
				this.updateDeletedBlog()
			}).catch(error => {
				this.processing = false
				if (error.response.status === 401) {
					this.$store.commit('auth/CLEAR_AUTH_USER', null)
					window.Bus.$emit('sign-out')
				} else {
					return this.$services.helpers.notification(error.response.data.message, 'error', this)
				}
			})
		},
		async updateDeletedBlog() {
			this.GET_CHECKED_BLOG_POST.map(item => {
				const blogIndex = this.GET_BLOG_POST.data.findIndex(itemIndex => item.id === itemIndex.id)
				this.GET_BLOG_POST.data.splice(blogIndex, 1)
			})
		},
		filterBlog() {
			let pageUrl = `?limit=${this.limit}`
			if (this.categoryFilter !== '') {
				pageUrl = pageUrl + `&category=${this.categoryFilter}`
			}
			if (this.dateRangeFilter !== '' && this.dateRangeFilter !== null) {
				if (this.dateRangeFilter.length > 0) {
					const data = this.dateRangeFilter
					pageUrl = pageUrl + `&date_from=${data[0]}&date_to=${data[1]}`
				} else {
					this.dateRangeFilter = ''
				}
			}
			this.$store.dispatch('portal/getBlogs', {url: pageUrl})
				.then(_ => {
					this.loading = false
				}).catch((err) => {
				if (err.response.status === 401) {
					this.$store.commit('auth/CLEAR_AUTH_USER', null)
					window.Bus.$emit('sign-out')
				}
			})
		},
		getAllEndCustomers() {
			this.$store.dispatch('portal/getAllEndCustomers')
		},
	},

}
</script>

<style lang="scss" scoped>
@import "./../../assets/variables.scss";

.app-main .app-main__inner {
	padding-top: 20px !important;
}

.table th {
	text-align: center !important;
	background: rgba(223, 224, 235, 0.5);
	padding: 0.4rem 0.55rem !important;
	color: #000000 !important;
}

.table-striped tbody:nth-of-type(2n+1) {
	background-color: rgba(0, 0, 0, 0.03);
}

.table tbody tr > td {
	// padding: 0.1rem 0.55rem !important;
	font-size: 12px !important;
	text-align: left;
	color: #6F6F6F;
}

.el-range-editor--small.el-input__inner {
	height: 40px;
}
</style>
